;(function($, window, document, undefined) {
  var $win = $(window);
  var $doc = $(document);
  var winH = $win.height();
  var winW = $win.width();
  var winO = $win.scrollTop();
  var qsRegex;

  // init Isotope
  var $grid = $('.news-ajax').isotope({
    itemSelector: '.col-sm-4',
    layoutMode: 'fitRows'
  });

  function refreshIsotope() {
    $grid.isotope({
      filter: function () {
        var quarter = $('input[name="quarter"]:checked').val().toString();
        var type = $('input[name="type"]:checked').val().toString();
        var filter = true; // []
        console.log(quarter);
        console.log(type);

        if (quarter !== 'all') {
          if (!$(this).data('filter-quarter') || $(this).data('filter-quarter').toString().indexOf(quarter) === -1) {
            filter = false;
          }
        }

        if (type !== 'all') {
          if (!$(this).data('filter-type') || $(this).data('filter-type').toString().indexOf(type) === -1) {
            filter = false;
          }
        }

        if(qsRegex){
          if(!$(this).text().match( qsRegex )){
            filter = false;
          }
        }

        return filter;
        // return filter.join('');
      }});


  }

  // debounce so filtering doesn't happen every millisecond
  function debounce( fn, threshold ) {
    var timeout;
    return function debounced() {
      if ( timeout ) {
        clearTimeout( timeout );
      }
      function delayed() {
        fn();
        timeout = null;
      }
      timeout = setTimeout( delayed, threshold || 100 );
    };
  }

// use value of search field to filter
  var $quicksearch = $('.quicksearch').keyup( debounce( function() {
    qsRegex = new RegExp( $quicksearch.val(), 'gi' );
    refreshIsotope();
  }, 200 ) );

  $('input[name="quarter"], input[name="type"]').change(function () {
    refreshIsotope();
  });

  function fixedHeader() {
    var winO = $win.scrollTop();
    var $header = $('.header');

    var isHeaderFixed = winO > 0;

    $header.toggleClass('fixed', isHeaderFixed);
  }

  // Intro Animation
  function introAnimation() {
    winO = $win.scrollTop();
    var $intro = $('.intro');
    var $slider = $('.slider--default');

    if ($win.width() > 1024) {
      if($intro.length) {
        var introPos = Math.min(winO/2, winH);

        $intro.find('.intro__image').css({
          'transform': 'translateY(' + introPos + 'px)',
          '-webkit-transform': 'translateY(' + introPos + 'px)',
          '-moz-transform': 'translateY(' + introPos + 'px)'
        });
      }

      if($slider.length) {
        var sliderPos = Math.min(winO/2, winH);

        $slider.find('.slider__slide-image').css({
          'transform': 'translateY(' + sliderPos + 'px)',
          '-webkit-transform': 'translateY(' + sliderPos + 'px)',
          '-moz-transform': 'translateY(' + sliderPos + 'px)'
        });
      }
    }
  }

  // Map Locations
  function mapLocations(container) {
    var ib = null;
    var contentString = null;
    var markers = [];
    var map;
    var marker;
    var label;
    var bounds = new google.maps.LatLngBounds();

    var $largeMap = document.getElementById(container);
    var	json_path = $largeMap.getAttribute('data-source');
    var	places = [];
    var	mapOptions = {
      center: null,
      zoom: 12,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      panControl: false,
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      overviewMapControl: false,
      styles:[{
        featureType: 'all',
        elementType: 'all',
        stylers: [
          { saturation: -100 }
        ]}
      ]
    };

    $.ajax({
      type: 'post',
      url: ajaxurl,
      data: { 'action': 'get_property_json' },
      dataType: 'json',
      success: function(response) {
        places = response.places;

        mapOptions.center = new google.maps.LatLng(places[0][1].lat, places[0][1].lng);

        map = new google.maps.Map($largeMap, mapOptions);

        infowindow = new google.maps.InfoWindow({
          content: '...loading'
        });

        for ( var j = 0; j < places.length; j++ ) {
          contentString = '<div class="map-popup">' +
              '<a href="' + places[j][1].link + '"><img src="' + places[j][1].image + '"/></a>' +
              '<a href="' + places[j][1].link + '"><div class="map-popup-content">' + '<h3>' + places[j][1].title + '</h3>' + '<h4>' + places[j][1].details + '</h4>' + '</div></a>' +
              '</div>';

          var markerIconImage = {
            url: '/wp-content/uploads/marker.png'
          };

          marker = new google.maps.Marker({
            position: new google.maps.LatLng(places[j][1].lat, places[j][1].lng),
            map: map,
            icon: markerIconImage,
            html: contentString
          });

          bounds.extend(marker.position);

          ib = new InfoBox(myOptions);

          google.maps.event.addListener(marker, 'click', function() {
            var markerNew = this;
            ib.setContent(this.html);
            ib.open(map, this);

            map.panTo(this.position);

            google.maps.event.addListener(ib, 'domready', function() {
              $('.infoBox').addClass('map-popup-outer');
            });
          });

          var myOptions = {
            content: '...loading',
            disableAutoPan: true,
            maxWidth: 0,
            zIndex: null,
            isHidden: false,
            pane: "floatPane",
            enableEventPropagation: false,
          };

          markers.push(marker);
        }

        var clusterer_styles = [{
          url: "/wp-content/uploads/marker-cluster.png",
          height: 30,
          width: 30,
          anchor: false,
          textColor: '#ffffff',
          fontFamily:"'Open Sans', Arial",
          textSize: 12
        }];

        var markerCluster = new MarkerClusterer(map, markers, {
          maxZoom: 15,
          styles: clusterer_styles
        });

        map.fitBounds(bounds);

        google.maps.event.addDomListener(window, 'resize', function() {
          var center = map.getCenter();

          google.maps.event.trigger(map, 'resize');
          map.fitBounds(bounds);
        });
      }
    });
  }

  $doc.ready(function() {
    // gf ajax
    $('.gf-ajax').each(function(){
      var $this = $(this);
      var validator = $this.validate({
        submitHandler: function(form){
          $.post(ajax_url, $(form).serialize(), function(result){
            if(result.is_valid){
              $this.replaceWith(result.confirmation_message);
            } else {
              var errors = {};

              $.each(result.validation_messages, function(key, value){

                if($('input[name="input_' + key + '"]').length <= 0){
                  errors['input_' + key + '_1'] = value;
                } else {
                  errors['input_' + key] = value;
                }
              });

              console.log(errors);
              console.log(result);

              validator.showErrors(errors);
            }
          }, 'json');
        }
      });
    });

    // Magnific Popup
    $('.js-btn-popup').magnificPopup({
      mainClass: 'popup-white',
      fixedContentPos: true
    });

    $('.video-popup').magnificPopup({
      type:'iframe'
    });

    $('.gallery-item').magnificPopup({
      mainClass: 'popup-white',
      type: 'image',
      gallery:{
        enabled:true
      }
    });

    $('.section-map').on('mouseenter', function() {

      $(this)
          .addClass('hovered');
    });

    // Initialize Map
    if ( $('#map-locations').length ) {
      mapLocations('map-locations');
    }

    // Video Play
    $('.video-item a').on('click', function(e) {
      e.preventDefault();
      var $videoContainer = $(this).closest('.video-item');
      var $video = $videoContainer.find('iframe');

      $videoContainer.addClass('expanded');

      $video[0].src += "&autoplay=1";
    });

    $('.video-item .btn-close').on('click', function(e) {
      e.preventDefault();

      $(this)
          .closest('.video-item')
          .removeClass('expanded');
    });

    // Navigation
    $('.nav a').each(function() {

      if ($(this).siblings('ul').length) {
        $(this).
            parent('li')
            .addClass('has-dropdown');
      }
    });

    $('.nav-mobile').on('click', function(e){
      e.preventDefault();

      $(this).toggleClass('active');

      $('.nav').toggleClass('expanded');

      if(!$(this).hasClass('active')) {
        $('.nav li').removeClass('expanded');
      }
    });

    $('.nav-sort ul li a').on( 'click', function(e) {
      var filterValue = $( this ).data('value');
      var $places = $('.places');
      $('.nav-sort ul li a').removeClass('current');
      $(this).addClass('current');

      if (filterValue === undefined ) {
        $places.find('.place').parent().show();
        return;
      }

      $places.find('.place').parent().hide();
      $places.find('.' + filterValue).show();


      e.preventDefault();
    });

    $('.nav a').on('click', function(e) {
      if ($(this).siblings('ul').length ) {
        if( $win.width() < 768 ) {
          if(!$(this).parent().hasClass('expanded')) {
            e.preventDefault();
          }
        }
        $(this)
            .parent()
            .addClass('expanded')
            .siblings()
            .removeClass('expanded')
            .parent()
            .siblings()
            .find('.expanded')
            .removeClass('expanded');
      }
    });

    // Responsive Tables
    $('table tr').each(function() {
      var $tr = $(this);
      var $th = $tr.closest('table').find('th');

      $tr.find('td').each(function(i) {
        $(this).attr('data-title', $th.eq(i).text());
      });
    });
  });

  $win
      .on('load', function() {
        // Slider
        $('.slider .slider__slides').owlCarousel({
          autoplay: true,
          items: 1,
          nav: true,
          loop: true,
          smartSpeed: 1000,
          autoplayTimeout: 10000,
          responsiveRefreshRate: 1,
          mouseDrag: false,
          autoHeight: false
        });

        $(function(){
          // get hash value
          var hash = window.location.hash;
          // now scroll to element with that id
          if(hash){
            $('html, body').delay(500).animate({ scrollTop: $(hash).offset().top - $('.header').outerHeight() });
          }
        });

      })
      .on('load scroll', function() {
        fixedHeader();

        introAnimation();
      });
})(jQuery, window, document);


